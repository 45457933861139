import React from 'react'
import '../styles/Footer.css'

export default function Footer() {
    return (
        <div>
            <h1 className='footerText'>&copy; 2021 Priya Nagda</h1>
        </div>
    )
}
